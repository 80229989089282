<template>
    <div class="mobile">
      <div class="mobile-header">
        <div class="logo"></div>
      </div>
      <div class="mobile-content" :style="mobileBg">
        <div class="suggest-content-box">
            <div class="suggest-content">
                <p>为了更好地探索</p>
                <p class="glow">3QMAP</p>
                <p>请使用电脑端进行浏览</p>
            </div>
        </div>
      </div>
    </div>
</template>
  
<script>  
import {image_url } from '../../const';
export default {
    data() {
        return {
        mobileBg: {
            background: 'url(' + image_url + '/Mobile/mobile_bg.png)',
            backgroundSize: '100% 100%'
        },
        };
    },
    components: {
        
    },
    mounted() {
        
    },
    methods: {
        
    }
}
</script>
  
<style scoped lang="scss">
  
  .mobile {
    background-color: #000;
    width: 100%;
    height: 100%;
  }
  
  .mobile-header {
    width: 100%;
    padding: 20px 0;

    .logo {
        margin: 0 auto;
        width: 120px;
        height: 40px;
        background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/3qlab_logo.png");
        background-size: 100% 100%;
    }
  }

  .mobile-content {
    width: 100%;
    height: 100%;
  }

.suggest-content-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    width: 70%;
    height: 254px;
    background: rgba(0,0,0,0.66);
    box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.67);
    border-radius: 15px;
    border: 1px solid #FFFFFF;
}

.suggest-content {    
    width: 100%;
    z-index: 9999;

    font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
    color: #FFFFFF;
    text-align: center;
    font-size: 18px;
    //line-height: 204px;
    p {
        margin: 0;
        line-height: 50px;
    }
}

.glow {
    text-shadow: 0 0 9px #ffffff;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #ffffff;
    color: black;
    font-size: 40px;
    font-family: 'SourceHanSansCN-Bold', 'SourceHanSansCN';
}
</style>
  